body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  text-align: center;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centre verticalement si assez d'espace */
  align-items: center;
  box-sizing: border-box; /* Inclut le padding */
  min-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 10px auto; /* Centré avec marge inférieure */
  width: 80px; /* Taille ajustable */
  height: auto;
}

h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px; /* Marge doublée */
  color: #333;
}

.query-container {
  margin-bottom: 40px; /* Marge doublée avec le bouton */
}

.query-container p {
  font-size: 18px;
  margin-bottom: 40px; /* Marge doublée */
}

input {
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.loader {
    margin: 20px auto;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.result {
  margin-top: 40px;
  margin-bottom: 100px;
  text-align: center;
  font-size: 18px;
  opacity: 1; /* Par défaut */
  transition: opacity 0.5s ease; /* Transition pour l'effet de fondu */
  min-height: 120px; /* Espace réservé pour éviter le décalage */
}

.result.fade-out {
  opacity: 0; /* Fondu */
}

.result h2 {
  margin-bottom: 10px;
}

.result p {
  color: #555;
}

.error {
  color: red;
  margin-top: 20px;
}

.footer {
  text-align: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-top: 1px solid #ddd;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
  font-size: 14px;
  margin-top: auto; /* Pousse le footer vers le bas si le contenu est petit */
}

.footer-buttons {
  margin: 10px 0;
}

.footer-button {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.footer-button:hover {
  text-decoration: underline;
}

.footer-text {
  color: #6c757d;
  font-style: italic;
  margin-top: 30px;
  font-size: 12px;
}

@media (max-width: 768px) {
    .App {
        padding: 10px;
        font-size: 16px;
    }

    .query-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .query-container p {
        text-align: center;
    }

    .query-container input {
        margin: 5px 0;
        width: 100%; /* Occupe toute la largeur sur mobile */
    }

    .footer {
        position: relative;
        margin-top: 20px;
    }
}
